<template>
  <div ref="nodeRef" class="container relative my-14 lg:my-20 min-h-[500px]">
    <Transition name="fade">
      <LazyTestimonials v-if="isSectionVisible" :data="data.testimonials" />
    </Transition>
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const nodeRef: Ref<HTMLDivElement | null> = ref(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  nodeRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
    }
  },
  {
    threshold: 0
  }
)

onUnmounted(() => {
  stop()
})
</script>
